import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import LoginForm from '@theme/components/user/LoginForm'

export default {
  components: {
    AppTitle,
    AppLink,
    LoginForm,
  },
  data() {
    return {
      benefits: [
        this.$t('LoginDropdownContent.benefits.benefit1'),
        this.$t('LoginDropdownContent.benefits.benefit2'),
        this.$t('LoginDropdownContent.benefits.benefit3'),
        this.$t('LoginDropdownContent.benefits.benefit4'),
        this.$t('LoginDropdownContent.benefits.benefit5'),
      ],
      paddingX: 0,
    }
  },
}
